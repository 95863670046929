import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppRouter } from "./routes/AppRouter";
import { queryClient } from "./services/react-query/queryClient";

function App() {
  const [apiKey, setApiKey] = useState(null);
  const [loading, setLoading] = useState(true);

  const baseURL = process.env.REACT_APP_GATEWAY_BASE_URL;

  // useEffect to fetch API key when the component mounts
  useEffect(() => {
    // Function to fetch API key
    const fetchApiKey = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${baseURL}/api-auth/get/gateway/key`);
        if (response.ok) {
          const data = await response.json();

          setApiKey(data?.data); // Assuming the API key is in the response object
          sessionStorage.setItem("apiKey", data?.data); // Save API key to session storage
        } else {
          throw new Error("Failed to fetch API key");
        }
      } catch (error) {
        setLoading(false);
        // Handle error
      } finally {
        setLoading(false);
      }
    };

    // Call fetchApiKey function
    fetchApiKey();
  }, [baseURL]);

  // Additional logic to execute after fetching the API key, if needed
  useEffect(() => {
    if (apiKey) {
      // Additional logic using the API key
    }
  }, [apiKey]);

  if (loading) {
    return (
      <div className="relative">
        <div className="absolute absoluteCentreY">
          <Spin />
        </div>
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppRouter />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnHover={false}
          role="alert"
          containerId={"toaster-id"}
          transition={Slide}
        />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
