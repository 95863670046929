import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "../components";

import Login from "../pages/auth/developer/login";

const AppRouter: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Login />} />
      </Route>
    </Routes>
  );
};

export { AppRouter };
